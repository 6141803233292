<template>
  <v-container
    id="dashboard-view"
    fluid
    tag="section"
    class="pa-0"
  >
    <v-card class="shiftWarp">
      <v-row class="infoWarp">
        <v-col cols="3">
          <div class="lblText">
            <h3>{{ $t('messages.commutingTime') }}</h3>
          </div>
          <div class="align-self-center mr-9">
            <v-menu
              v-model="showPickerStartAt"
              :close-on-content-click="false"
              :nudge-right="200"
              :nudge-bottom="100"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="startTime"
                  name="pa-1"
                  single-line
                  outlined
                  v-bind="attrs"
                  clearable
                  append-icon="mdi-calendar"
                  readonly
                  v-on="on"
                />
              </template>
              <v-time-picker
                v-model="startTime"
                :first-day-of-week="0"
                locale="ja-jn"
                @input="showPickerStartAt = false"
              />
            </v-menu>
          </div>
        </v-col>
        <v-col cols="3">
          <div class="lblText">
            <h3>{{ $t('messages.leaveTime') }}</h3>
          </div>
          <div class="align-self-center mr-9">
            <v-menu
              v-model="showPickerEndAt"
              :close-on-content-click="false"
              :nudge-right="200"
              :nudge-bottom="100"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="endTime"
                  name="pa-1"
                  single-line
                  outlined
                  v-bind="attrs"
                  clearable
                  append-icon="mdi-calendar"
                  readonly
                  v-on="on"
                />
              </template>
              <v-time-picker
                v-model="endTime"
                :first-day-of-week="0"
                locale="ja-jn"
                @input="showPickerEndAt = false"
              />
            </v-menu>
          </div>
        </v-col>
        <v-col cols="3">
          <div class="lblText">
            <h3>{{ $t('messages.workingHour') }}</h3>
          </div>
          <div class="align-self-center mr-9">
            <v-text-field
              v-model="workingHour"
              outlined
              type="number"
            />
          </div>
        </v-col>
        <v-col
          cols="2"
          class="inputDisplay"
        >
          <v-btn
            large
            block
            color="forth"
            class="mb-5 pt-0 white--text inputDisplayBtn"
          >
            <span class=" font-weight-bold text-h5">
              {{ $t('messages.search') }}</span>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <div class="lblText">
            <h3>{{ $t('messages.parttimeJobList') }}</h3>
          </div>
          <div class="align-self-center mr-9">
            <v-select
              v-model="job"
              :items="listJobs"
              item-text="name"
              item-value="value"
              name="pa-1"
              outlined
              :no-data-text="$t('table.messages.no_data')"
            />
          </div>
        </v-col>
        <v-col cols="3">
          <div class="lblText">
            <h3>{{ $t('messages.sex') }}</h3>
          </div>
          <div class="align-self-center mr-9">
            <v-select
              v-model="sex"
              :items="sexArray"
              item-text="name"
              item-value="value"
              name="pa-1"
              outlined
              :no-data-text="$t('table.messages.no_data')"
            />
          </div>
        </v-col>
        <v-col cols="3">
          <div class="lblText">
            <h3>{{ $t('messages.language') }}</h3>
          </div>
          <div class="align-self-center mr-9">
            <v-select
              v-model="language"
              :items="languages"
              item-text="name"
              item-value="value"
              name="pa-1"
              outlined
              :no-data-text="$t('table.messages.no_data')"
            />
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            v-model="selected"
            :headers="headers"
            :options.sync="options"
            :items="items"
            class="elevation-1 "
            stle
            :loading-text="$t('table.messages.loading')"
            :loading="loading"
            :footer-props="{
              itemsPerPageOptions: [5, 10, 15, 100],
              showFirstLastPage: false,
              'items-per-page-text': $t('table.messages.items_per_page'),
            }"
            fixed-header
            show-select
            :no-data-text="$t('table.messages.no_data')"
          >
            <template
              v-slot:footer.page-text
              class="mr-0"
            >
              {{ $t('table.messages.page') }}
              {{ options.page }}
            </template>

            <template v-slot:item.name="props">
              <a href="">
                {{ props.item.name ? props.item.name : '' }}
              </a>
            </template>

            <template v-slot:item.index="props">
              {{ props.index + 1 }}
            </template>

            <template v-slot:item.sex="props">
              {{ props.item.sex ? $t('messages.male') : $t('messages.female') }}
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
  export default {
    name: 'ShiftAssignment',
    data () {
      return {
        showPickerStartAt: false,
        showPickerEndAt: false,
        startTime: '00:00',
        endTime: '00:00',
        workingHour: 0,
        listJobs: [
          { name: '全体', value: 'theEntire' },
          { name: '本工場のみ', value: 'mainFactoryOnly' },
        ],
        job: '',
        languages: [
          { name: 'ネパール', value: 'nepal' },
          { name: 'ベトナム', value: 'vietnam' },
          { name: '中国', value: 'china' },
          { name: '全体', value: ' theEntire' },
        ],
        language: '',
        sex: '',
        sexArray: [
          { name: '男', value: true },
          { name: '女', value: false },
          { name: '両方', value: null },
        ],
        headers: [],
        items: [],
        options: {},
        loading: false,
        selected: [],
      }
    },
    watch: {
      selected (value) {
        value.forEach(val => {
          this.items.forEach(item => {
            if (val.id === item.id) {
              item.checked = true
            } else {
              item.checked = false
            }
          })
        })

        this.items.sort((a, b) => {
          return a.checked === b.checked ? 0 : a.checked ? -1 : 1
        })
      },
    },
    mounted () {
      this.headers = [
        {
          text: this.$t('table.headers.index'),
          align: 'start',
          value: 'index',
          class: 'pr-0 pl-2 font-weight-bold',
          sortable: false,
          width: '4rem',
        },
        {
          text: this.$t('table.headers.agencyId'),
          align: 'center',
          value: 'agencyId',
          class: 'pr-0 pl-2 font-weight-bold',
          sortable: false,
          width: '8rem',
        },
        {
          text: this.$t('table.headers.name'),
          align: 'start',
          value: 'name',
          class: 'pr-0 pl-2 font-weight-bold',
          sortable: false,
          width: '8rem',
        },
        {
          text: this.$t('table.headers.language'),
          align: 'start',
          value: 'language',
          class: 'pr-0 pl-2 font-weight-bold',
          sortable: false,
          width: '8rem',
        },
        {
          text: this.$t('table.headers.dob'),
          align: 'center',
          value: 'dob',
          class: 'pr-0 pl-2 font-weight-bold',
          sortable: false,
          width: '8rem',
        },
        {
          text: this.$t('table.headers.sex'),
          align: 'start',
          value: 'sex',
          class: 'pr-0 pl-2 font-weight-bold',
          sortable: false,
          width: '4rem',
        },
        {
          text: this.$t('table.headers.studentIDCardDeadline'),
          align: 'center',
          value: 'studentIDCardDeadline',
          class: 'pr-0 pl-2 border-right font-weight-bold',
          sortable: false,
          width: '8rem',
        },
        {
          text: this.$t('table.headers.residenceCardExpirationDate'),
          align: 'center',
          value: 'residenceCardExpirationDate',
          class: 'pr-0 pl-2 font-weight-bold',
          sortable: false,
          width: '8rem',
        },
        {
          text: this.$t('table.headers.contactPhone'),
          align: 'start',
          value: 'contactPhone',
          class: 'pr-0 pl-2 font-weight-bold',
          sortable: false,
          width: '8rem',
        },
        {
          text: this.$t('table.headers.facebookLink'),
          align: 'start',
          value: 'facebookLink',
          class: 'pr-0 pl-2 font-weight-bold',
          sortable: false,
          width: '10rem',
        },
        {
          text: this.$t('table.headers.workExperience'),
          align: 'start',
          value: 'workExperience',
          class: 'pr-0 pl-2 font-weight-bold',
          sortable: false,
          width: '12rem',
        },
      ]

      this.items = [
        {
          id: 1,
          agencyId: '40123',
          name: 'Lê Hồng Minh 1',
          language: 'Việt Nam',
          dob: '2000/08/29',
          sex: true,
          checked: false,
        },
        {
          id: 2,
          agencyId: '40124',
          name: 'Lê Hồng Minh 2',
          language: 'Việt Nam',
          dob: '2000/08/29',
          sex: true,
          checked: false,
        },
        {
          id: 3,
          agencyId: '40125',
          name: 'Lê Hồng Minh 3',
          language: 'Việt Nam',
          dob: '2000/08/29',
          sex: true,
          checked: false,
        },
        {
          id: 4,
          agencyId: '40126',
          name: 'Lê Hồng Minh 4',
          language: 'Việt Nam',
          dob: '2000/08/29',
          sex: true,
          checked: false,
        },
        {
          id: 5,
          agencyId: '40127',
          name: 'Lê Hồng Minh 5',
          language: 'Việt Nam',
          dob: '2000/08/29',
          sex: true,
          checked: false,
        },
        {
          id: 6,
          agencyId: '40128',
          name: 'Lê Hồng Minh 6',
          language: 'Việt Nam',
          dob: '2000/08/29',
          sex: true,
          checked: false,
        },
        {
          id: 7,
          agencyId: '40129',
          name: 'Lê Hồng Minh 7',
          language: 'Việt Nam',
          dob: '2000/08/29',
          sex: true,
          checked: false,
        },
        {
          id: 8,
          agencyId: '40130',
          name: 'Lê Hồng Minh 8',
          language: 'Việt Nam',
          dob: '2000/08/29',
          sex: true,
          checked: false,
        },
        {
          id: 9,
          agencyId: '40131',
          name: 'Lê Hồng Minh 9',
          language: 'Việt Nam',
          dob: '2000/08/29',
          sex: true,
          checked: false,
        },
        {
          id: 10,
          agencyId: '40132',
          name: 'Lê Hồng Minh 10',
          language: 'Việt Nam',
          dob: '2000/08/29',
          sex: true,
          checked: false,
        },
        {
          id: 11,
          agencyId: '40133',
          name: 'Lê Hồng Minh 11',
          language: 'Việt Nam',
          dob: '2000/08/29',
          sex: true,
          checked: false,
        },
        {
          id: 12,
          agencyId: '40134',
          name: 'Lê Hồng Minh 12',
          language: 'Việt Nam',
          dob: '2000/08/29',
          sex: true,
          checked: false,
        },
        {
          id: 13,
          agencyId: '40135',
          name: 'Lê Hồng Minh 13',
          language: 'Việt Nam',
          dob: '2000/08/29',
          sex: true,
          checked: false,
        },
        {
          id: 14,
          agencyId: '40136',
          name: 'Lê Hồng Minh',
          language: 'Việt Nam',
          dob: '2000/08/29',
          sex: true,
          checked: false,
        },
      ]
    },
  }
</script>

<style scoped lang="sass">
.border-right
  border-right: thin solid rgba(0, 0, 0, 0.12)!important

.shiftWarp
  margin-top: 15px
  padding: 0 50px

  .infoWarp
    padding-top: 20px

  .lblText
    margin-top: 10px

.dateRadio
  display: flex
  align-items: center

  h3
    margin-right: 20px
.dateWarp
  display: flex
  justify-content: space-between

  .icon-fall
    font-size: 20px
    font-weight: bold
    margin-top: 10px
.btnAddHour
  width: 41px
  min-width: 41px!important
.inputDisplay
  display: flex
  align-items: center
  .inputDisplayBtn
    margin: 0px!important
</style>
